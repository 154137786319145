<template>
  <!-- Coming soon page-->
  <b-overlay :show="showLoading" spinner-variant="primary">
    <div class="misc-wrapper">
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          PHARMACONNECT
        </h2>
      </b-link>

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            Renforcez votre sécurité dès le début ! 🚀
          </h2>
          <p class="mb-3">
            Pour une protection optimale de votre compte, personnalisez dès maintenant votre mot de passe lors de votre
            première connexion.
          </p>

          <!-- form -->
          <b-form inline class="row justify-content-center m-0 mb-2" @submit.prevent>

            <b-form-input class="col-12 col-md-12 mb-1 mr-md-2" :state="state" type="password"
              placeholder="Saisir le nouveau mot de passe" v-model="password" autocomplete="nope" />
            <b-form-input class="col-12 col-md-12 mb-0 mr-md-2" :state="stateN" type="password"
              placeholder="Ressaisir le mot de passe" v-model="passwordN" autocomplete="off" />
            <b-form-invalid-feedback>
              {{ invalidFeedback }}
            </b-form-invalid-feedback>

            <b-button :disabled="btnDisabled" variant="primary" class="mb-1 mt-2 btn-sm-block" type="submit"
              @click="changePassword">
              Sauvegarder
            </b-button>
          </b-form>

          <b-img fluid :src="imgUrl" alt="Coming soon page" />
        </div>
      </div>
    </div>
  </b-overlay>
  <!-- / Coming soon page-->
</template>

<script>

import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

export default {
  components: {
    VuexyLogo,
  },
  data() {
    return {
      showLoading: false,
      password: "",
      passwordN: "",
      downImg: require('@/assets/images/pages/coming-soon.svg'),
    }
  },
  // created(){
  //   console.log(localStorage.getItem('tokens'));
  //   console.log(JSON.parse(localStorage.getItem('tokens')));
  // },
  computed: {
    btnDisabled() {
      return this.state == true && this.stateN == true ? false : true;
    },
    invalidFeedback() {
      if (!this.state)
        return "Le mot de passe doit contenir au moins 8 caractères incluant au moins un chiffre."
      if (!this.stateN)
        return "Le mot de passe de confirmation ne correspond pas au premier mot de passe. Veuillez les harmoniser."
      return "";
    },
    state() {
      if (this.password == "")
        return null;
      if (this.password.length < 8) {
        return false;
      }
      // Vérifier s'il y a au moins un chiffre dans le mot de passe
      if (!/\d/.test(this.password)) {
        return false;
      }
      // Si toutes les vérifications passent, le mot de passe est valide
      return true;
    },
    stateN() {
      if (this.passwordN == "")
        return null;
      if (this.passwordN != this.password)
        return false;
      return true;
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/coming-soon-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    changePassword() {
      this.showLoading = true;
      let compte = {
        passwordNew: this.password,
        username: JSON.parse(localStorage.getItem('userData')).username,
      }
      this.$http.post("account/updatePasswordFirstTime", compte).then((resp) => {
        this.showLoading = false;
        console.log(resp);
        console.log(getHomeRouteForLoggedInUser(JSON.parse(localStorage.getItem('userData')).role));
        this.$router.replace(getHomeRouteForLoggedInUser(JSON.parse(localStorage.getItem('userData')).role));
      }).catch((err) => {
        this.showLoading = false;
        console.log(err.response);
        this.showToast('danger', 'AlertOctagonIcon', 'Mise à jour', err.response.data);
      });
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
